import React, { FC, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Error, Table, useTable } from '@funfarm/kit';
import TableRenderer from '@funfarm/kit/Table/TableRenderer';

import { toApiDateString } from 'helpers/formatDates';
import { FinanceTransactionsResponse } from 'types/finances';
import { getTransactions } from 'actions/finances';

import DrawerLayout from 'components/Finances/DrawerLayout';
import TableSkeleton from 'components/Finances/TableSkeleton';
import transferColumns from 'components/Finances/TransfersHistory/transfers.columns';
import TransfersFilter from 'components/Finances/TransfersHistory/TransfersFilter';
import { TourContext } from 'components/Tournaments/TourProvider';

import css from '../finances.module.scss';

const TransactionsHistory: FC = () => {
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [operationType, setOperationType] = useState<"in" | "out">();

    const tableContext = useContext(TourContext);
    const tableState = useTable({
        pagination: { take: 50, skip: 0 },
        order: { start_date: 'asc' },
    });
    const { data, isLoading, isError } = useQuery<FinanceTransactionsResponse>({
        queryKey: ["finances", "transactions", startDate, endDate, operationType],
        queryFn: () => getTransactions(
            toApiDateString(startDate),
            toApiDateString(endDate),
            operationType,
        ),
    });

    return (
        <DrawerLayout
            pageHeadingProps={{
                title: "История переводов",
            }}
            metricsProps={{
                caption: "Текущий счет",
                value: 15357.8,
                currency: "USD"
            }}
        >
            <TransfersFilter
                startDate={startDate}
                endDate={endDate}
                operationType={operationType}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setOperationType={setOperationType}
            />
            {isLoading ? (
                <TableSkeleton />
            ) : (isError || !data?.transactions) ? (
                <Error message="Ошибка получения данных" />
            ) : (
                <Table
                    cells={8}
                    className={css.balanceHistoryTable}
                    {...tableContext}
                    {...tableState}
                >
                    <TableRenderer
                        rows={data.transactions}
                        columns={transferColumns}
                        classNames={{
                            header: css.balanceHistoryHeader,
                            row: css.balanceHistoryRow,
                        }}
                    />
                </Table>
            )}
        </DrawerLayout>
    );
};

export default TransactionsHistory;
