import React, { FC, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Error } from '@funfarm/kit';
import IconDocumentText from '@funfarm/kit/Icon/icons/DocumentText';
import IconHistory from '@funfarm/kit/Icon/icons/History';

import { CurrentCalculationResponse, FinanceMetricsProps } from 'types/finances';
import { getCurrentCalculation } from 'actions/finances';

import MetricsSection from 'components/Finances/MetricsSection';
import PlayersPackages from 'components/Finances/PlayerPackages/PlayersPackages';
import TransactionsHistory from 'components/Finances/TransactionsHistory/TransactionsHistory';
import TransfersHistory from 'components/Finances/TransfersHistory/TransfersHistory';

import css from './finances.module.scss';

type FinanceMetricsComponentProps = {
    data?: FinanceMetricsProps;
    isError: boolean;
    isLoading: boolean;
};

const FinanceMetrics: FC<FinanceMetricsComponentProps> = ({
    data,
    isError,
    isLoading,
}) => {
    const { data: currentData } = useQuery<CurrentCalculationResponse>({
        queryKey: ["finances", "calculation/current"],
        queryFn: () => getCurrentCalculation()
    });

    const profit = useMemo(
        () => {
            return Number(currentData?.package.profit || data?.profit)
        },
        [currentData?.package.profit, data?.profit]
    );

    return (isError) ? (
        <Error message="Ошибка получения данных" />
    ) : (
        <div className={css.financesContent}>
            <MetricsSection
                title="Текущий пакет"
                button="Пакеты игрока"
                buttonIcon={<IconDocumentText
                    size="medium"
                />}
                drawerContent={<PlayersPackages/>}
                isLoading={isLoading}
                items={[
                    { caption: "Дата начала пакета", value: data?.datepack },
                    { caption: "Процент от бай-ин", value: data?.percbuyin, percentage: true },
                    { caption: "Процент от профита", value: data?.percprofit, percentage: true },
                    { caption: "Сыграно МТТ в пакете", value: data?.mttpack },
                    { caption: "Профит пакета", value: profit, currency: "USD" },
                    {
                        caption: "Профит игрока",
                        value: profit > 0
                            ? profit * Number(data?.percprofit) / 100
                            : profit * Number(data?.percbuyin) / 100,
                        currency: "USD"
                    },
                    {
                        caption: "Профит проекта",
                        value: !data?.profit
                            ? undefined
                            : data?.profit > 0
                                ? data?.profit
                                : data?.profit,
                        currency: "USD"
                    },
                ]}
            />

            <MetricsSection
                title="Деньги игрока"
                button="История операций"
                buttonIcon={<IconHistory
                    size="medium"
                />}
                drawerContent={<TransfersHistory/>}
                isLoading={isLoading}
                items={[
                    { caption: "Нужно от игрока", value: data?.needuser, currency: "USD" },
                    { caption: "После расчёта", value: data?.wmoney, currency: "USD" },
                    { caption: "Доступно к выводу", value: data?.dvijpl, currency: "USD" },
                ]}
            />

            <MetricsSection
                title="Деньги проекта"
                button="История переводов"
                buttonIcon={<IconHistory
                    size="medium"
                />}
                drawerContent={<TransactionsHistory/>}
                isLoading={isLoading}
                items={[
                    { caption: "Нужно от проекта", value: data?.need, currency: "USD" },
                    { caption: "Средняя загрузка", value: data?.averload, currency: "USD" },
                    { caption: "Денег проекта у игрока", value: data?.moneyproject, currency: "USD" },
                    {
                        caption: "Излишки",
                        value: data?.overage,
                        currency: "USD",
                        ...(data?.overage && data.overage > 0 && {
                            className: css.redMetrics,
                            // button: (
                            //     <Button
                            //         view="outlined"
                            //         size="medium"
                            //         color="red"
                            //         label="К переводам"
                            //         onClick={() => navigate("/finance-requests")}
                            //     />
                            // ),
                        })},
                ]}
            />
        </div>
    );
};

export default FinanceMetrics;
