import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Badge, Row, Skeleton } from '@funfarm/kit';

import { CurrentCalculationResponse } from 'types/finances';
import { getCurrentCalculation } from 'actions/finances';

const BalanceHistoryFilter: FC = () => {
    const { data, isLoading, isError } = useQuery<CurrentCalculationResponse>({
        queryKey: ["finances", "calculation/current"],
        queryFn: () => getCurrentCalculation(),
    });

    return isLoading ? (
        <Skeleton/>
    ) : !isError && data?.package && (
        <Row
            align="center"
            gap={2}
        >
            <Badge color={getColor(data.package.status)}>
                {getCaption(data.package.status)}
            </Badge>
            {[
                ["Дата начала", data.package.datestart],
                // ["Условия", data.package.condition],
                ["Профит пакета", data.package.profit],
            ].map((item, index) => item[1] && (
                <Badge
                    key={index}
                    color="dark"
                >
                    {item.join(" ")}
                </Badge>
            ))}
        </Row>
    );
};

const getColor = (status: string) =>
    status === "open" ? "green"
        : status === "hold" ? "blue"
            : "default";

const getCaption = (status: string) =>
    status === "open" ? "Пакет открыт"
        : status === "hold" ? "Пакет на расчёте"
            : status === "close" ? "Пакет закрыт"
                : status;

// const PackageStatusBadge: FC = ({ status }: { status: string }) =>
//     status === "open" ? (
//
//     ) : status === "closed" ? (
//
//     ) : (
//
//     )

export default BalanceHistoryFilter;
