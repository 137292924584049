import React, { FC, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Button, Dialog, Error, Paper } from '@funfarm/kit';
import { Column } from '@funfarm/kit/Column';

import { BalanceItem } from 'types/finances';
import { getBalances } from 'actions/finances';

import TableSkeleton from 'components/Finances/TableSkeleton';
import CreateRequest from 'components/Finances/Withdrawals/CreateRequest';

import BalanceHistory from '../BalanceHistory/BalanceHistory';

import BalanceLine from './BalanceLine';

import css from '../finances.module.scss';

type BalancePanelProps = {
    refetchMetrics: () => void;
};

const BalancePanel: FC<BalancePanelProps> = ({
    refetchMetrics,
}) => {
    const [historyOpen, setHistoryOpen] = useState(false);
    const [balanceToChange, setBalanceToChange] = useState<BalanceItem>();

    const { data, isLoading, isError, refetch } = useQuery<{ rooms: BalanceItem[] } >(
        {
            queryKey: ["finances", "balances"],
            queryFn: () => getBalances(),
            refetchInterval: 60000,
        }
    );

    const [balances, inTheWay] = useMemo(() => {
        const result: [BalanceItem[], BalanceItem[]] = [[], []];
        data?.rooms.forEach((item) => {
            if (item.to_way) result[1].push(item);
            else result[0].push(item);
        });
        return result;
    }, [data]);

    return (
        <div className={css.balancePanel}>
            <Paper
                header="Баланс"
                helper={
                    <Button
                        view="text"
                        size="small"
                        color="secondary"
                        onClick={() => setHistoryOpen(true)}
                    >
                        Посмотреть историю
                    </Button>
                }
            >
                {isLoading ? (
                    <TableSkeleton columns={3}/>
                ) : (isError || !data?.rooms) ? (
                    <Error message="Ошибка получения данных" />
                ) : (
                    <Column gap={3}>
                        {!isLoading && balances.map((details: BalanceItem, index) => (
                            <BalanceLine
                                item={details}
                                openChangeBalance={(item: BalanceItem) => setBalanceToChange(item)}
                                refetchMetrics={refetchMetrics}
                                key={index}
                            />
                        ))}
                        {!isLoading && inTheWay.length && (
                            <>
                                <hr />
                                <h3>В пути</h3>
                                {inTheWay.map((details, index) => (
                                    <BalanceLine
                                        item={details}
                                        openChangeBalance={(item: BalanceItem) => setBalanceToChange(item)}
                                        refetchMetrics={refetchMetrics}
                                        key={index}
                                    />
                                ))}
                            </>
                        )}
                        {balanceToChange && (
                            <Dialog
                                header="Создать запрос"
                                open={!!balanceToChange}
                                onClose={() => setBalanceToChange(undefined)}
                            >
                                <CreateRequest
                                    balance={balanceToChange}
                                    onClose={() => setBalanceToChange(undefined)}
                                    onSuccess={refetch}
                                />
                            </Dialog>
                        )}
                    </Column>
                )}
            </Paper>

            {historyOpen && (
                <Dialog
                    header="История заполнения балансов"
                    open={historyOpen}
                    onClose={() => setHistoryOpen(false)}
                    xlarge
                >
                    <BalanceHistory/>
                </Dialog>
            )}
        </div>
    );
};

export default BalancePanel;
