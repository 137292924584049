import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Error, IError, Input, Row, UploaderArea } from '@funfarm/kit';

import { createWithdrawalRequest } from 'actions/finances';

import css from 'components/Finances/finances.module.scss';
import OperationTypeSelect from 'components/Finances/TransfersHistory/OperationTypeSelect';
import AccountSelect from 'components/Select/AccountSelect';

type AddOperationProps = {
    onClose: () => void,
    onSuccess: () => void,
};

const AddOperation: FC<AddOperationProps> = ({
    onClose,
    onSuccess,
}) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const{
        control,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    return (
        <form
            onSubmit={handleSubmit((formData) => {
                setSubmitted(true);
                createWithdrawalRequest(
                    formData.account,
                    Number(formData.amount),
                    formData.operationType,
                    formData.comment,
                )
                    .then(() => {
                        onClose();
                        onSuccess();
                    })
                    .catch((error: IError) => {
                        setError(error.message);
                    })
                    .finally(() => setSubmitted(false));
            })}
            noValidate
        >
            {error && (
                <Error message={error} view="box" />
            )}
            <AccountSelect control={control} />
            <OperationTypeSelect control={control} />
            <Controller
                control={control}
                name="amount"
                render={({ field }) => (
                    <Input
                        {...field}
                        type="number"
                        label="Сумма"
                        placeholder="Введите нужную сумму"
                        iconRight={
                            <span className={css.currencyInner}>$</span>
                        }
                        error={!!errors.amount}
                    />
                )}
            />
            <Controller
                control={control}
                name="comment"
                render={({ field }) => (
                    <Input
                        {...field}
                        placeholder="Можете дополнить ваш запрос комментарием"
                        // labelPosition="inside"
                    />
                )}
            />
            <div className={css.dropzone}>
                <UploaderArea
                    label="Переместите скриншот или нажмите для выбора"
                />
            </div>
            <Row className={css.formButtons}>
                <Button
                    color="default"
                    label="Отмена"
                    loading={submitted}
                    onClick={onClose}
                />
                <Button
                    color="secondary"
                    label="Добавить"
                    type="submit"
                    loading={submitted}
                    disabled={!isValid}
                />
            </Row>
        </form>
    );
};

export default AddOperation;
