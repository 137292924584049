import React, { FC, useState } from 'react';

import { Badge, Button, IconChecked, IconPlus, IError, Input, Loader, Row } from '@funfarm/kit';
import { Column } from '@funfarm/kit/Column';
import IconClock from '@funfarm/kit/Icon/icons/Clock';

import { formatDateString } from 'helpers/formatDates';
import { BalanceItem } from 'types/finances';
import { changeBalance } from 'actions/finances';

import css from '../finances.module.scss';
import classNames from 'classnames';

type BalanceLineProps = {
    item: BalanceItem;
    openChangeBalance: (id: BalanceItem) => void,
    refetchMetrics: () => void,
}

const BalanceLine: FC<BalanceLineProps> = ({
    item,
    openChangeBalance,
    refetchMetrics,
}) => {
    const [loading, setLoading] = useState(false);
    const [showCheckmark, setShowCheckmark] = useState(false);
    const [previous, setPrevious] = useState(item.balance_curr);

    return (
        <Row
            spaceBetween
            align="center"
        >
            <Column>
                <h4>{item.room_title}</h4>
                <p>{formatDateString(item.date_change)}</p>
            </Column>
            <Row
                spaceBetween
                align="center"
                gap={1}
            >
                {!!item.requests_amount && (
                    <Badge
                        className={css.topUp}
                        label={"+" + item.requests_amount}
                        color="orange"
                        size="small"
                        icon={
                            <IconClock
                                fill="success"
                                size="xxxlarge"
                            />
                        }
                    />
                )}
                <Input
                    value={String(item.balance_curr)}
                    className={css.balanceValue}
                    iconRight={
                        <span className={css.currencyInnerBalance}>
                            <span className={classNames(
                                css.checkmarkWrapper,
                                (showCheckmark || loading) && css.visible
                            )}>
                                {loading ? (
                                    <span className={css.loadingWrapper}>
                                        <Loader className={css.loading}/>
                                    </span>
                                ) : showCheckmark && (
                                    <IconChecked
                                        size="large"
                                        fill="green"
                                    />
                                )}
                            </span>
                            {item.currency_symbol}
                        </span>
                    }
                    onBlur={(e) => {
                        if (Number(e.target.value) === previous) return;
                        setLoading(true);
                        const newValue = e.target.value.includes(",")
                            ? e.target.value.replace(",", ".")
                            : e.target.value;
                        changeBalance(item.room_id, newValue)
                            .then(() => {
                                refetchMetrics();
                                setLoading(false);
                                setShowCheckmark(true);
                                setPrevious(Number(newValue))
                                setInterval(
                                    () => setShowCheckmark(false),
                                    3000
                                )
                            })
                            .catch((error: IError) => {
                                console.error("Error while updating balance: ", error);
                            });
                    }}
                />
                <Button
                    view="light"
                    color="secondary"
                    tabIndex={-1}
                    onClick={() => openChangeBalance(item)}
                    icon={<IconPlus />}
                />
            </Row>
        </Row>
    );
}

export default BalanceLine;
