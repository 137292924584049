import React from "react";
import { useTranslation } from "react-i18next";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

import { IconAlignTextLeft, IconChartLine, IconDocumentSearch, IconPPT, IconUsers } from "@funfarm/kit";

import { preparePath } from "helpers";
import { IRoute } from "types";

import FeatureRequest from "components/FeatureRequests/FeatureRequest";
import FinanceBalance from "components/Finances/Balance/FinanceBalance";
import WithdrawalRequests from "components/Finances/Withdrawals/WithdrawalRequests";
import { Info } from "components/Info";
import { ErrorLayout, Layout, NavigationLayout } from "components/Layout";
import { Player } from "components/Player";
import { Profile } from "components/Profile";
import { Select } from "components/Select";
import { Statistic } from "components/Statistic";
import { Tournaments } from "components/Tournaments";
import { TournamentsAll } from "components/Tournaments/TournamentsAll";
import { TournamentsLimits } from "components/Tournaments/TournamentsLimits/TournamentsLimits";
import { TournamentsPermissions } from "components/Tournaments/TournamentsPermissions/TournamentsPermissions";


export const useRoutes = () => {
    const { t } = useTranslation();

    const routes: IRoute[] = [
        {
            path: "/",
            Component: Layout,
            ErrorBoundary: ErrorLayout,
            handle: {
                visible: false,
                layout: <NavigationLayout />
            },
            children: [
                {
                    index: true,
                    element: (
                        <Navigate to={"info"} replace />
                    ),
                    handle: {
                        visible: false
                    }
                },
                {
                    path: "auth",
                    Component: () => <>Auth</>,
                    handle: {
                        layout: <>Авторизация успешна.<br/>Вернитесь на предыдущую страницу.</>,
                        visible: false
                    }
                },
                {
                    path: "profile",
                    Component: Profile,
                    handle: {
                        name: t("Profile"),
                        icon: <IconUsers />,
                        crumb: false,
                        visible: false
                    }
                },
                {
                    path: "info",
                    Component: Info,
                    handle: {
                        name: t("Info"),
                        icon: <IconPPT />,
                        crumb: false
                    }
                },
                {
                    path: "player",
                    Component: Player,
                    handle: {
                        name: t("Rang changes"),
                        icon: <IconChartLine />,
                        crumb: false
                    }
                },
                {
                    path: "statistic",
                    Component: Statistic,
                    handle: {
                        name: t("Statistic"),
                        icon: <IconAlignTextLeft />,
                        crumb: false
                    }
                },
                {
                    path: "select",
                    Component: Select,
                    handle: {
                        name: t("Select"),
                        icon: <IconDocumentSearch />,
                        crumb: false
                    }
                },
                {
                    path: "tournaments",
                    Component: Tournaments,
                    handle: {
                        name: t("Tournaments"),
                        icon: <IconDocumentSearch />,
                        crumb: false
                    },
                    children: [
                        {
                            index: true,
                            element: <TournamentsAll />,
                            handle: {
                                visible: false
                            }
                        },
                        {
                            path: "permissions",
                            element: <TournamentsPermissions />,
                            handle: {
                                visible: false
                            }
                        },
                        {
                            path: "limits",
                            element: <TournamentsLimits />,
                            handle: {
                                visible: false
                            }
                        }
                    ]
                },
                {
                    path: "finance-balance",
                    Component: FinanceBalance,
                    handle: {
                        name: t("Finances"),
                        icon: <IconDocumentSearch />,
                        crumb: false
                    }
                },
                {
                    path: "finance-requests",
                    Component: WithdrawalRequests,
                    handle: {
                        visible: false
                    }
                }


            ]
        },
        {
            path: "feature-requests",
            Component: FeatureRequest,
            handle: {
                visible: false
            }
        }
    ];

    return preparePath(routes);
};

export const Routes = () => {
    const routes: IRoute[] = useRoutes();

    return <RouterProvider router={createBrowserRouter([
    // ...getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI]).map((r) => r.props),
        ...routes
    ])} />;
};
