import React, {
    ChangeEvent,
    ChangeEventHandler,
    ForwardedRef,
    forwardRef,
    InputHTMLAttributes,
    ReactElement,
    useCallback,
    useEffect,
    useState
} from 'react';
import classNames from 'classnames';

import { ELabelPosition, IconPreviewClose, IconPreviewOpen, Tooltip } from '@funfarm/kit';

import css from './input.module.scss';


interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    disabled?: boolean,
    required?: boolean,
    readonly?: boolean,
    value?: string | number,
    label?: string,
    labelPosition?: keyof typeof ELabelPosition,
    onChange?: ChangeEventHandler<HTMLInputElement>,
    onFocus?: ChangeEventHandler<HTMLInputElement>,
    onBlur?: ChangeEventHandler<HTMLInputElement>,
    onInput?: ChangeEventHandler<HTMLInputElement>,
    className?: string,
    error?: string | boolean,
    displayError?: boolean,
    iconLeft?: ReactElement,
    iconRight?: ReactElement,
    addonLeft?: { [label: string]: string },
    addonRight?: { [label: string]: string },
    dataTestId?: string,
    helper?: ReactElement | string,
    description?: string,
    size?: number,
}


export const Input = forwardRef((props: IProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
        type = 'text', id, name, label, labelPosition = ELabelPosition.top,
        error, displayError,
        required, disabled, readonly, placeholder,
        onChange, onFocus, onBlur, onInput,
        addonLeft, addonRight,
        className, style, helper, size,
        dataTestId,
        iconLeft, description,
        // remove from rest
        iconRight: _ir, value: _v,
        ...rest
    } = props;
    let { iconRight } = props;
    // value has to be a string
    const value = props.value ?
        props.value.toString() : '';

    const [currentValue, setValue] = useState<string>(value);
    const [focus, setFocus] = useState<boolean>(false);
    const [displayLabel, setDisplayLabel] = useState<boolean>(true);
    const [showPass, setShowPass] = useState<boolean>(false);

    if(type === 'password') {
        iconRight =
            showPass ?
                <IconPreviewOpen onClick={() => setShowPass(s => !s)} /> :
                <IconPreviewClose onClick={() => setShowPass(s => !s)} />;
    }


    useEffect(() => {
        if(type === 'date' && value !== '') {
            const date = new Date(value);

            setValue(date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0'));
        } else
            setValue(value);

        setDisplayLabel(labelPosition === ELabelPosition.inside && !currentValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, type]);


    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if(type === 'date' && event.target.value !== '') {
            const date = new Date(event.target.value);

            setValue(date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0'));
        } else {
            setValue(event.target.value);
        }

        if(onChange)
            onChange(event);
    }, [onChange, type]);


    const handleFocus = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFocus(true);
        setDisplayLabel(false);

        if(onFocus)
            onFocus(event);
    }, [onFocus]);


    const handleBlur = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFocus(false);
        setDisplayLabel(labelPosition === ELabelPosition.inside && !currentValue);

        if(onBlur)
            onBlur(event);
    }, [onBlur, labelPosition, currentValue]);


    const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if(type === 'number') {
            event.target.value = event.target.value.replace(/\D/g, '');
        }

        if(onInput) {
            onInput(event);
        }
    }, [onInput, type]);


    return (
        <div className={classNames(
            css[type],
            (error && css.error),
            (disabled && css.disabled),
            (readonly && css.readonly),
            css.labelPosition,
            css[labelPosition],
            css[`size-${size}`],
            className,
        )} style={style}>
            {
                label &&
                <label htmlFor={name} className={classNames(css.label, css[labelPosition], !displayLabel && css['focus'])}>
                    {label}
                    {
                        required &&
                        <span className={css.mark}>*</span>
                    }
                    {
                        helper &&
                        (typeof helper === 'string' ?
                            <Tooltip description={helper} /> :
                            React.cloneElement(helper, {
                                name: '',
                                className: classNames(helper.props.className, css.helper)
                            }))
                    }
                </label>
            }
            <div className={css['errorWrapper']}>
                <div className={css['addonWrapper']}>
                    {
                        addonLeft &&
                        <div className={classNames(css.addon, css.left)}>{addonLeft.label}</div>
                    }
                    <div className={classNames(
                        css.wrapper,
                        disabled && css['wrapperDisabled'],
                        readonly && css['wrapperReadonly'],
                        error && css['wrapperError'],
                        addonLeft && css['wrapperAddonLeft'],
                        addonRight && css['wrapperAddonRight'],
                        focus && css['focus'],
                    )}>
                        {
                            iconLeft &&
                            React.cloneElement(iconLeft, { className: classNames(iconLeft.props.className, css['iconLeft'], (iconLeft.props.onClick || iconLeft.props.button) && css.action) })
                        }
                        <input
                            type={showPass ? 'text' : type}
                            id={id ?? name}
                            name={name}
                            disabled={disabled}
                            required={required}
                            readOnly={readonly}
                            placeholder={placeholder}
                            value={currentValue}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onInput={handleInput}
                            data-testid={dataTestId}
                            className={classNames(
                                labelPosition === ELabelPosition.inside && !!value && css.focus
                            )}
                            ref={ref}
                            {...rest}
                        />
                        {
                            iconRight &&
                            React.cloneElement(iconRight, { className: classNames(iconRight.props.className, css['iconRight'], (iconRight.props.onClick || iconRight.props.button) && css.action) })
                        }
                    </div>
                    {
                        addonRight &&
                        <div className={classNames(css.addon, css.right)}>{addonRight.label}</div>
                    }
                </div>
                {
                    description &&
                    <p className={css.description}>{description}</p>
                }
                {
                    displayError && error &&
                    <p className={css.error}>{error}</p>
                }
            </div>
        </div>
    );
});

