import React, { FC, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Button, Dialog, Error, IconClose, IconPlus, Table, useTable } from '@funfarm/kit';
import TableRenderer from '@funfarm/kit/Table/TableRenderer';

import { toApiDateString } from 'helpers/formatDates';
import { WithdrawalsResponse } from 'types/finances';
import { getWithdrawals } from 'actions/finances';

import DrawerLayout from 'components/Finances/DrawerLayout';
import TableSkeleton from 'components/Finances/TableSkeleton';
import transactionColumns from 'components/Finances/TransactionsHistory/transactions.columns';
import AddOperation from 'components/Finances/TransfersHistory/AddOperation';
import TransfersFilter from 'components/Finances/TransfersHistory/TransfersFilter';
import { NavigationContext } from 'components/Layout/NavigationProvider';
import { TourContext } from 'components/Tournaments/TourProvider';

import css from '../finances.module.scss';

const TransfersHistory: FC = () => {
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [operationType, setOperationType] = useState<"in" | "out">();
    const [operationOpened, setOperationOpened] = useState(false);

    const tableContext = useContext(TourContext);
    const tableState = useTable({
        pagination: { take: 50, skip: 0 },
        order: { start_date: 'asc' },
    });
    const { data, isLoading, isError, refetch } = useQuery<WithdrawalsResponse>({
        queryKey: ["finances", "withdrawals", startDate, endDate, operationType],
        queryFn: () => getWithdrawals(
            toApiDateString(startDate),
            toApiDateString(endDate),
            operationType,
        )
    });
    const { closeDrawer } = useContext(NavigationContext);

    return  (
        <DrawerLayout
            pageHeadingProps={{
                title: "История операций",
                renderButtons: () => (
                    <>
                        <Button
                            color="secondary"
                            iconLeft={<IconPlus/>}
                            onClick={() => setOperationOpened(true)}
                        >
                            Добавить операцию
                        </Button>
                        <Button
                            view="contained"
                            color="default"
                            size="xlarge"
                            className={css.drawerClose}
                            icon={
                                <IconClose fill="white" size="xlarge" />
                            }
                            onClick={closeDrawer}
                        />
                        {operationOpened && (
                            <Dialog
                                header="Добавить операцию"
                                open={operationOpened}
                                onClose={() => setOperationOpened(false)}
                            >
                                <AddOperation
                                    onClose={() => setOperationOpened(false)}
                                    onSuccess={refetch}
                                />
                            </Dialog>
                        )}
                    </>
                ),
            }}
            metricsProps={{
                caption: "Получено от проекта",
                // value: data?.total || "-",
                value: "-",
            }}
        >
            <TransfersFilter
                startDate={startDate}
                endDate={endDate}
                operationType={operationType}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setOperationType={setOperationType}
            />
            {isLoading ? (
                <TableSkeleton />
            ) : (isError || !data?.withdraws) ? (
                <Error message="Ошибка получения данных" />
            ) : (
                <Table
                    cells={8}
                    {...tableContext}
                    {...tableState}
                >
                    <TableRenderer
                        rows={data.withdraws}
                        columns={transactionColumns}
                        classNames={{
                            header: css.balanceHistoryHeader,
                            row: css.balanceHistoryRow,
                        }}
                    />
                </Table>
            )}
        </DrawerLayout>
    )
};

export default TransfersHistory;
