import React from 'react';
import { t } from 'i18next';

import { Badge } from '@funfarm/kit';

import { durationInDays, formatPeriod, isFutureDate } from 'helpers/formatDates';
import { money } from 'helpers/numbers';

import { PlayerPackageItem } from 'components/Finances/finances.types';

const playersPackageColumns = [
    {
        header: "Период",
        renderCell: (item: PlayerPackageItem) => {
            return formatPeriod(item.date_start, item.date_end);
        },
    },
    {
        header: "Длительность",
        renderCell: (item: PlayerPackageItem) => {
            const count = durationInDays(item.date_start, item.date_end);
            return count + " " + t('day', { count });
        },
    },
    {
        header: "Условия",
        fieldName: "condition",
    },
    {
        header: "Результат, $",
        renderCell: (item: PlayerPackageItem) =>
            money(item.profit, 'USD'),
    },

    {
        header: "Доля игрока, $",
        renderCell: (item: PlayerPackageItem) =>
            money(item.profit_user, 'USD'),
    },
    {
        header: "Статус пакета",
        fieldName: "packageStatus",
        renderCell: (item: PlayerPackageItem) => {
            // @todo how to define the status?
            const status = getStatus(item);
            return (
                <Badge
                    size="small"
                    color={getColor(status)}
                >
                    {status}
                </Badge>
            )
        }
    },
];

const getColor = (status: string) =>
    status === 'Открыт'
        ? 'blue'
        : status === 'На рассчёте'
            ? 'orange'
            : status === 'Закрыт'
                ? 'green'
                : 'default';

const getStatus = (item: PlayerPackageItem) => {
    if (isFutureDate(item.date_end)) return "Открыт";
    return "Закрыт";
};

export default playersPackageColumns;
