import { differenceInCalendarDays, format, isAfter, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

import i18n, { localeMap } from 'helpers/i18n';

export const DATE_TIME_FORMAT = 'dd MMM HH:mm';

export const toApiDateString = (date: Date | undefined) =>
    date?.toISOString().split('T')[0];

export const timestampToDate = (
    input: number,
    dateFormat = DATE_TIME_FORMAT,
) =>
    format(new Date(input * 1000), dateFormat);

export const formatDateString = (
    input: string,
    dateFormat = DATE_TIME_FORMAT,
) =>
    format(new Date(input), dateFormat);

export const formatMonth = (date: string) => {
    return format(parseISO(date), 'LLL', { locale: localeMap[i18n.language] })
}
export const formatPeriod = (date_start: string, date_end: string): string => {
    const start = parseISO(date_start);
    const end = parseISO(date_end);

    // Check if start and end are in the same year
    const sameYear = start.getFullYear() === end.getFullYear();

    // Format with or without the year
    const startFormat = sameYear ? 'dd MMM' : 'dd.MM.yyyy';
    const endFormat = sameYear ? 'dd MMM' : 'dd.MM.yyyy';

    const result = `${format(start, startFormat, { locale: ru })} - ${format(end, endFormat, { locale: ru })}`;
    return sameYear ? result.replaceAll(".", "") : result;
};

export const durationInDays = (date_start: string, date_end: string): number => {
    const start = parseISO(date_start);
    const end = parseISO(date_end);

    return differenceInCalendarDays(end, start);
};

export const isFutureDate = (date: string): boolean => {
    const parsedDate = parseISO(date);
    const now = new Date();
    return isAfter(parsedDate, now);
};
