import React, { FC, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Badge, Button, Dialog, IconChecked, IconClose, Skeleton } from '@funfarm/kit';

import { CurrentCalculationResponse } from 'types/finances';
import { getCurrentCalculation } from 'actions/finances';

import DrawerLayout from 'components/Finances/DrawerLayout';
import ClosePackage from 'components/Finances/PlayerPackages/ClosePackage';
import PackagesTable from 'components/Finances/PlayerPackages/PackagesTable';
import { NavigationContext } from 'components/Layout/NavigationProvider';

import css from '../finances.module.scss';

const PlayersPackages: FC = () => {
    const [completingPackageOpen, setCompletingPackageOpen] = useState(false);
    const { closeDrawer } = useContext(NavigationContext);
    const { data, isLoading, isError } = useQuery<CurrentCalculationResponse>({
        queryKey: ["finances", "calculation/current"],
        queryFn: () => getCurrentCalculation()
    });

    return (
        <DrawerLayout
            pageHeadingProps={{
                title: "Пакеты игрока",
                renderButtons: () => (
                    <>
                        {isLoading ? (
                            <Skeleton/>
                        ) : !isError && data?.package && (
                            <>
                                {data.package.status === "hold" && (
                                    <Badge
                                        color="green"
                                        icon={<IconChecked/>}
                                    >
                                        Заявка на расчёт принята. Расчёт будет сделан в течение 72 часов
                                    </Badge>
                                )}
                                {data.package.status === "open" && (
                                    <Button
                                        color="secondary"
                                        onClick={() => setCompletingPackageOpen(true)}
                                    >
                                        Закрыть текущий пакет
                                    </Button>
                                )}
                                {completingPackageOpen && (
                                    <Dialog
                                        header="Закрыть пакет"
                                        open={completingPackageOpen}
                                        onClose={() => setCompletingPackageOpen(false)}
                                    >
                                        <ClosePackage
                                            data={data}
                                            onClose={() => setCompletingPackageOpen(false)}
                                        />
                                    </Dialog>
                                )}
                            </>
                        )}
                        <Button
                            view="contained"
                            color="default"
                            size="xlarge"
                            className={css.drawerClose}
                            icon={
                                <IconClose fill="white" size="xlarge" />
                            }
                            onClick={closeDrawer}
                        />
                    </>
                )
            }}
        >
            <PackagesTable/>
        </DrawerLayout>
    );
};

export default PlayersPackages;
