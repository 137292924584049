import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Column } from '@funfarm/kit/Column';

import { FinanceMetricsProps } from 'types/finances';
import { getFinanceMetrics } from 'actions/finances';

import FinanceHeading from 'components/Finances/FinanceHeading';

import FinanceMetrics from '../FinanceMetrics';

import BalancePanel from './BalancePanel';

import css from '../finances.module.scss';

const FinanceBalance: FC = () => {
    const { data, isLoading, isError, refetch } = useQuery<{ data: FinanceMetricsProps } >({
        queryKey: ["finances", "metrics"],
        queryFn: () => getFinanceMetrics(),
        refetchInterval: 60000,
    });

    return (
        <>
            <FinanceHeading/>
            <div className={css.financesGrid}>
                <Column className={css.balancePanel} gap={2}>
                    <BalancePanel
                        refetchMetrics={refetch}
                    />
                    {/*<CrossRatesPanel/>*/}
                </Column>
                <FinanceMetrics
                    data={data?.data}
                    isError={isError}
                    isLoading={isLoading}
                />
            </div>
        </>
    );
}

export default FinanceBalance;
