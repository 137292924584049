import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Error, IError, Input, Row } from '@funfarm/kit';

import { BalanceItem } from 'types/finances';
import { createRequest } from 'actions/finances';

import AccountSelect from 'components/Select/AccountSelect';

import css from '../finances.module.scss';

type CreateRequestProps = {
    balance?: BalanceItem,
    onClose: () => void;
    onSuccess: () => void;
}

const CreateRequest: FC<CreateRequestProps> = ({
    balance,
    onClose,
    onSuccess,
}) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const {
        control,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
    });

    return (
        <div className={css.closePackageForm}>
            <form
                onSubmit={handleSubmit((formData) => {
                    setSubmitted(true);
                    createRequest(
                        balance ? balance.room_id : formData.account,
                        formData.amount,
                        formData.comment,
                    )
                        .then((response) => {
                            if (response?.errors) {
                                setError(
                                    Object.values(response.errors).join('\n'),
                                );
                            } else {
                                onClose();
                                onSuccess();
                            }
                        })
                        .catch((error: IError) => {
                            setError(error.message);
                        })
                        .finally(() => setSubmitted(false));
                })}
                noValidate
                className={css.form}
            >
                {error && <Error message={error} view="box" />}
                {balance ? (
                    <Input
                        label="Выбор счёта"
                        value={balance.room_title}
                        disabled
                    />
                ) : (
                    <>
                        <AccountSelect
                            control={control}
                            error={!!errors.account}
                        />
                        <Input
                            label="Данные счёта"
                            value="Здесь отобразятся данные счёта"
                            disabled
                        />
                    </>
                )}
                <Controller
                    control={control}
                    name="amount"
                    render={({ field }) => (
                        <Input
                            {...field}
                            label="Сумма"
                            type="number"
                            placeholder="Введите нужную сумму"
                            iconRight={
                                <span className={css.currencyInner}>$</span>
                            }
                            error={!!errors.amount}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="comment"
                    render={({ field }) => (
                        <Input
                            {...field}
                            placeholder="Можете дополнить ваш запрос комментарием"
                            // labelPosition="inside"
                        />
                    )}
                />
                <Row className={css.formButtons}>
                    <Button
                        color="default"
                        loading={submitted}
                        label="Отмена"
                        onClick={onClose}
                    />
                    <Button
                        color="secondary"
                        type="submit"
                        loading={submitted}
                        disabled={!isValid}
                        label="Создать запрос"
                    />
                </Row>
            </form>
        </div>
    );
};

export default CreateRequest;

