import { API } from 'helpers/apiInstance';
import {
    BalanceHistoryResponse,
    BalanceItem, CalculationConditionsResponse, CrossRatesResponse, CurrentCalculationResponse,
    FinanceCalculation,
    FinanceMetricsProps,
    FinanceTransactionsResponse, OperationTypesResponse, RoomsResponse, UsedRoomsResponse,
    WithdrawalRequestsResponse,
    WithdrawalsResponse,
} from 'types/finances';

export const getTransactions = (
    date_start = "2024-10-01",
    date_end = "2024-12-29",
    operation_type: "in" | "out" | undefined,
) =>
    API.get<any, FinanceTransactionsResponse>(
        '/check/finances/transactions',
        { params: {
            date_start,
            date_end,
            operation_type,
        }},
    );

export const getWithdrawals = (
    date_start = "2024-10-01",
    date_end = "2024-12-29",
    operation_type: "in" | "out" | undefined,
) =>
    API.get<any, WithdrawalsResponse>(
        '/check/finances/withdraws',
        { params: {
            date_start,
            date_end,
            operation_type,
        }},
    );

export const getFinanceMetrics = () =>
    API.get<any, {
        data: FinanceMetricsProps,
    }>('/check/finances/brm');

export const getBalances = () =>
    API.get<any, {
        rooms: BalanceItem[],
    }>('/check/balances');

export const getBalancesHistory = () =>
    API.get<any, BalanceHistoryResponse>(
        '/check/balances/history'
    );

export const changeBalance = (
    room_id: number,
    amount: number | string,
) =>
    API.put('/check/balances', {
        room_id,
        amount,
    });

export const getCalculations = () =>
    API.get<any, {
        calculations: FinanceCalculation[],
    }>('/check/calculations');

export const openNewPackage = (
    condition_id: number,
) =>
    API.post<any, {
        calculations: FinanceCalculation[],
    }>('/check/calculations', {
        condition_id,
    });

export const getCurrentCalculation = () =>
    API.get<any, CurrentCalculationResponse>(
        '/check/calculations/current'
    );

export const getCalculationConditions = () =>
    API.get<any, CalculationConditionsResponse>(
        '/check/calculations/available-conditions'
    );

export const getCrossRates = () =>
    API.get<any, CrossRatesResponse>('/check/library/currencies');

export const getRooms = () =>
    API.get<any, RoomsResponse>(
        '/check/library/rooms'
    );

export const getWithdrawalTypes = () =>
    API.get<any, OperationTypesResponse>(
        '/check/library/withdrawal-types'
    );

export const getUsedRooms = () =>
    API.get<any, UsedRoomsResponse>(
        '/check/balances/used-rooms'
    );

export const getWithdrawalRequests = () =>
    API.get<any, WithdrawalRequestsResponse>(
        '/check/requests'
    );

export const createRequest = (
    room_id: number,
    amount: number,
    comment?: string,
) =>
    API.post<any, any>('/check/requests', {
        room_id,
        amount,
        comment,
    });

export const createWithdrawalRequest = (
    room_id: number,
    amount: number,
    type_id: number,
    comment?: string,
) =>
    API.post<any, any>('/check/finances/withdraws', {
        room_id,
        amount,
        comment,
        image_url: "-",
        type_id,
    });

export const createSendMoneyRequest = (
    request_id: number,
    amount: number,
    fee: number,
    comment?: string,
    screenshot_url?: string,
) =>
    API.post<any, any>(`/check/requests/${request_id}/send`, {
        amount,
        fee,
        comment,
        screenshot_url,
    });
